<template>
  <div class="agent">
    <!--
    <textarea
      v-model="message"
      placeholder="Type aq message..."
      @input="handleTyping"
      @keydown.enter="sendMessage"
    ></textarea>
    <button @click="sendMessage">Send</button>
    <div v-if="isTyping">The agent is typing...</div>
    -->
    <div v-if="apiError !== 0">
      Hiba történt az API-hívás során: {{ apiError }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import io from "socket.io-client";

export default {
  data() {
    return {
      socket: null,
      message: "",
      isTyping: false,
      userToken: null, // Alapértelmezés szerint null
      clientToken: "cgpkXwy3fE6f9ZrDWuXI6Wuz", // Csak az azonosításhoz
      groupToken: null,
      rconToken: null, // Alapértelmezés szerint null
      receivedMessages: [], // Hozzáadva az üzenetek tárolásához
      apiError: 0,
    };
  },
  mounted() {
    // Ellenőrizzük a localStorage-t
    this.userToken = localStorage.getItem("userToken");
    this.groupToken = localStorage.getItem("groupToken");
    this.rconToken = localStorage.getItem("rconToken");
    const storedToken = localStorage.getItem("token"); // Lekérjük a korábban tárolt tokent

    // Ha bármelyik token hiányzik, API hívás indítása
    if (storedToken) {
      if (!this.rconToken || !this.groupToken || !this.userToken) {
        this.fetchUserDataFromAPI(storedToken);
      } else {
        // Ha mind3 megtalálható, indítsuk el a socket kapcsolatot
        this.initializeSocket();
      }
    }
  },
  methods: {
    async fetchUserDataFromAPI(storedToken) {
      try {
        const response = await axios.post(
          "https://rcon.octagent.com/api/index.php",
          {
            action: "fetch_user_data", // Példa: az API hívás akciója
            rconToken: this.rconToken,
            groupToken: this.groupToken,
            token: storedToken, // Beletesszük a tárolt tokent az API hívásba
          }
        );

        // Ellenőrizzük a választ
        if (response.data.status === "Success") {
          // Eltároljuk a tokeneket a localStorage-ba
          this.userToken = response.data.userToken; // Példa az API válaszra
          this.groupToken = response.data.groupToken; // Példa az API válaszra
          this.rconToken = response.data.rconToken;
          localStorage.setItem("userToken", this.userToken);
          localStorage.setItem("groupToken", this.groupToken);
          localStorage.setItem("rconToken", this.rconToken);

          // Socket kapcsolat inicializálása a tokenekkel
          this.initializeSocket();
          this.apiError = 0;
        } else {
          this.apiError = 1;
          console.error("Hiba az API válaszban:", response.data.message);
        }
      } catch (error) {
        this.apiError = 1;
        console.error("Hiba a felhasználói adatok lekérésekor:", error);
      }
    },

    initializeSocket() {
      this.socket = io("https://socket.octagent.com:3000", {
        withCredentials: true,
        extraHeaders: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      this.socket.on("connect", () => {
        this.socket.emit("connected", {
          rconToken: this.rconToken,
          groupToken: this.groupToken,
          userToken: this.userToken,
        });
      });

      this.socket.on("typing", () => {
        console.log("client typing");
        this.isTyping = true;
        setTimeout(() => (this.isTyping = false), 5000);
      });

      this.socket.on("message", (message) => {
        console.log("received message from client: " + message);
        this.receivedMessages.push(message);
      });
    },

    handleTyping() {
      if (this.message.trim()) {
        console.log("agent typing");
        this.socket.emit("typing", {
          rconToken: this.rconToken,
          groupToken: this.groupToken,
          userToken: this.userToken,
          clientToken: this.clientToken,
        });
      }
    },

    sendMessage() {
      if (this.message.trim()) {
        console.log("agent sent message");
        this.socket.emit("message", {
          rconToken: this.rconToken,
          groupToken: this.groupToken,
          userToken: this.userToken,
          clientToken: this.clientToken,
          message: this.message,
        });
        this.message = "";
      }
    },

    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie =
        name +
        "=" +
        (value || "") +
        expires +
        "; path=/; SameSite=None; Secure";
    },

    getCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style scoped>
.agent {
  display: flex;
  flex-direction: column;
}
textarea {
  margin-bottom: 10px;
}
</style>
