<template>
  <div>
    <h2>
      {{ email && token ? "Új jelszó megadása" : "Jelszó visszaállítása" }}
    </h2>
    <form @submit.prevent="handleSubmit">
      <div v-if="!confirmCodeSent">
        <input
          type="email"
          v-model="emailInput"
          placeholder="Email cím"
          required
        />
        <button type="submit">Jelszó visszaállítása</button>
      </div>
      <div v-else>
        <input
          type="text"
          v-model="confirmCode"
          placeholder="Megerősítő kód"
          required
        />
        <label v-if="confirmCodeError">{{ confirmCodeError }}</label>

        <input
          type="password"
          v-model="newPassword"
          placeholder="Új jelszó"
          required
        />
        <label v-if="passwordError">{{ passwordError }}</label>

        <input
          type="password"
          v-model="confirmPassword"
          placeholder="Jelszó megerősítése"
          required
        />
        <label v-if="passwordMismatchError">{{ passwordMismatchError }}</label>

        <button type="submit">Jelszó megváltoztatása</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      emailInput: "",
      confirmCode: "",
      newPassword: "",
      confirmPassword: "",
      email: new URLSearchParams(window.location.search).get("email"),
      confirmCodeSent: false, // Ezzel nyomon követjük, hogy elküldtük-e a reset kérelmet
      confirmCodeError: "",
      passwordError: "",
      passwordMismatchError: "",
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.$router.push("/"); // Navigáljunk a főoldalra, ha már be van jelentkezve
    }
  },
  methods: {
    async handleSubmit() {
      this.clearErrors(); // Minden hibaüzenetet törlünk

      if (!this.confirmCodeSent) {
        // Jelszó visszaállító kérelem elküldése
        try {
          const response = await axios.post(
            "https://rcon.octagent.com/api/index.php",
            {
              action: "reset_password",
              email: this.emailInput,
            }
          );
          if (response.data && response.data.status === "Success") {
            this.email = this.emailInput;
            this.confirmCodeSent = true;
          } else {
            //itt hibát kellene tárolni
          }
          console.log(response.data); // Válasz kezelése
        } catch (error) {
          console.error(error); // Hibakezelés
        }
      } else {
        // Új jelszó és kód validálása
        if (!this.validateInputs()) return;

        try {
          const response = await axios.post(
            "https://rcon.octagent.com/api/index.php",
            {
              action: "update_password",
              email: this.email,
              confirm_code: this.confirmCode,
              password: this.newPassword,
            }
          );

          if (response.data && response.data.status === "Error") {
            if (response.data.error === "confirm_code_invalid") {
              this.confirmCodeError = "Helytelen megerősítő kód";
            } else if (response.data.error === "confirm_code_expired") {
              this.confirmCodeError = "Lejárt a megerősítő kód";
            }
          } else {
            this.$router.push("/login"); // Siker esetén átirányítás a login oldalra
          }
        } catch (error) {
          console.error(error); // Hibakezelés
        }
      }
    },
    validateInputs() {
      let isValid = true;

      // Megerősítő kód validálás
      if (!this.confirmCode) {
        this.confirmCodeError = "Adja meg az email-ben kapott megerősítő kódot";
        isValid = false;
      } else if (this.confirmCode.length !== 16) {
        this.confirmCodeError = "Helytelen megerősítő kód";
        isValid = false;
      }

      // Jelszó validálás
      if (!this.newPassword) {
        this.passwordError = "Kérjük adjon meg egy jelszót";
        isValid = false;
      } else if (this.newPassword.length < 8) {
        this.passwordError =
          "A jelszónak legalább 8 karakter hosszúnak kell lennie";
        isValid = false;
      }

      // Jelszavak egyezésének ellenőrzése
      if (this.newPassword !== this.confirmPassword) {
        this.passwordMismatchError = "A jelszavak nem egyeznek";
        isValid = false;
      }

      return isValid;
    },
    clearErrors() {
      this.confirmCodeError = "";
      this.passwordError = "";
      this.passwordMismatchError = "";
    },
  },
};
</script>
