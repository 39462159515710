<template>
  <div>
    <h2>Fiók Aktiválása</h2>
    <div>Aktiválás folyamatban...</div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  async created() {
    try {
      const params = new URLSearchParams(window.location.search);
      const email = params.get("email");
      const token = params.get("token");

      if (email && token) {
        const response = await axios.post(
          "https://rcon.octagent.com/api/index.php",
          {
            action: "activate",
            email: email,
            token: token,
          }
        );

        if (response.data.status === "Success") {
          this.$router.push("/login");
        }
      }
    } catch (error) {
      console.error(error); // Hibakezelés
    }
  },
};
</script>
