import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AuthLogin from "../components/AuthLogin.vue";
import AuthActivate from "../components/AuthActivate.vue";
import AuthRegister from "../components/AuthRegister.vue";
import AuthResetPassword from "../components/AuthResetPassword.vue";
import AuthLogout from "../components/AuthLogout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: AuthLogin,
    meta: { requiresAuth: false, layout: "default" },
  },
  {
    path: "/register",
    name: "Register",
    component: AuthRegister,
    meta: { requiresAuth: false, layout: "default" },
  },
  {
    path: "/activate",
    name: "Activate",
    component: AuthActivate,
    meta: { requiresAuth: false, layout: "default" },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: AuthResetPassword,
    meta: { requiresAuth: false, layout: "default" },
  },
  {
    path: "/logout",
    name: "Logout",
    component: AuthLogout,
    meta: { requiresAuth: true, layout: "default" },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "/conversations",
    name: "conversations",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Conversations.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "/automations",
    name: "automations",
    component: () => import("../views/Automations.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import("../views/Customers.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("../views/Statistics.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Settings.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/Help.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "/stats",
    name: "stats",
    component: () => import("../views/StatsView.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "/tasks/:id",
    component: () => import("../views/TaskView.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
  {
    path: "*",
    component: () => import("../views/NotFoundView.vue"),
    meta: { requiresAuth: true, layout: "app" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//megnézzük, hogy kell-e auth az adott útvonalra és ha kell akkor nézzük a token-t
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    next("/login");
  } else {
    next();
  }
});

export default router;
